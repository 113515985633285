<template>
  <div>
    <v-card>
        <v-card-title>Disaster</v-card-title>
        <v-card-text>Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem consequuntur repellat natus ex repellendus sit soluta assumenda adipisci velit cupiditate! Rerum mollitia ea eaque, at earum eum. Quas, culpa tenetur!
        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reprehenderit, ipsa tempore. Quasi nesciunt illum, odit magnam dolor minus ab in laborum molestiae, reprehenderit quisquam nam a soluta expedita, cumque sunt.
        </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "muncitydisaster",
  data() {
    return {
      data: [],
    };
  },

  

  methods: {
    
  },
};
</script>
