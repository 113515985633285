<template>
  <div>
    <v-card>
        <v-card-title>Livestock Production</v-card-title>
        <v-card-text>
            <v-data-table
    :headers="headers"
    :items="data"
    :items-per-page="tableitems"
  ></v-data-table>
        </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "muncitylivestock",
  data() {
    return {
      data: [],
      tableitems:10,
      headers:[
          
          { text: 'Classification', value: 'desc' },
          { text: 'Description', value: 'variety' },
          { text: 'Age (days)',  align: 'end',value: 'age' },
          { text: 'Production (Has)',  align: 'end',value: 'available' },
        ],
    };
  },

  mounted(){
    this.getData()
  },

  methods: {
    async getData(){
      let muncity=this.$store.state.muncity.selected.munname.toLowerCase()
      
      await this.$store.dispatch('mais/getLivestock',muncity)
      this.data=await this.$store.state.mais.livestock
    }
  },
};
</script>
