<template>
  <div>
    <v-toolbar>
      <router-link to="/muncity">
        <v-btn icon>
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </router-link>
          
      <v-toolbar-title >{{ data.munname }}</v-toolbar-title>
      <template v-slot:extension>
        <v-tabs v-model="tab" centered slider-color="green">
          <v-tab key="1"> Crops </v-tab>
          <v-tab key="2"> Livestock </v-tab>
          <v-tab key="3"> Poultry </v-tab>
          <!-- <v-tab key="4"> Fishery </v-tab>
          <v-tab key="5"> Trees </v-tab> -->
          <v-tab key="6"> Data Uploads </v-tab>
          <!-- <v-tab key="7"> Disaster Records </v-tab> -->
        </v-tabs>
      </template>
    </v-toolbar>
    <v-tabs-items v-model="tab">
      <v-tab-item key="1">
        <muncitycrops></muncitycrops>
      </v-tab-item>
      <v-tab-item key="2">
        <muncitylivestock></muncitylivestock>
      </v-tab-item>
        <v-tab-item key="3">
        <muncitypoultry></muncitypoultry>
      </v-tab-item>
        <!-- <v-tab-item key="4">
        <muncityfishery></muncityfishery>
      </v-tab-item>
        <v-tab-item key="5">
          <muncitytrees></muncitytrees>
      </v-tab-item> -->
        <v-tab-item key="6">
        <muncitydatauploads></muncitydatauploads>
      </v-tab-item>
        <!-- <v-tab-item key="7">
        <muncitydisaster></muncitydisaster>
      </v-tab-item> -->
    </v-tabs-items>
  </div>
</template>

<script>
import muncitycrops from "./crops.vue";
import muncitylivestock from "./livestock.vue";
import muncitypoultry from "./poultry.vue";
import muncityfishery from "./fishery.vue";
import muncitytrees from "./trees.vue";
import muncitydatauploads from "./dataupload.vue";
import muncitydisaster from "./disaster.vue";

export default {
  name: "MunCityDetails",
  data() {
    return {
      data: [],
      tab: "",
    };
  },

  components: {
    muncitycrops,
    muncitylivestock,
    muncitypoultry,
    muncityfishery,
    muncitytrees,
    muncitytrees,
    muncitydatauploads,
    muncitydisaster,
  },

  mounted() {
    this.getData();
  },

  methods: {
    async getData() {
      this.data = await this.$store.state.muncity.selected;
    },
  },
};
</script>
