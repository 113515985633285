<template>
  <div>
    <v-card>
        <v-card-title>Data Uploads</v-card-title>
        <v-card-text>
            <v-data-table
    :headers="headers"
    :items="data"
    :items-per-page="tableitems"
  ></v-data-table>
        </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "muncitydatauploads",
  data() {
    return {
      data: [],
      tableitems:10,
      headers:[
          
          { text: 'Uploads Date', value: 'logdate' },
          { text: 'Farms', value: 'farms' },
          { text: 'Crops', value: 'crops' },
          { text: 'Livestock', value: 'livestock' },
          { text: 'Poultry', value: 'poultry' },
          { text: 'Trees', value: 'trees' },
          // { text: 'Fishery', value: 'fishery' },
        ],
    };
  },

  mounted(){
    this.getData()
  },

  methods: {
    async getData(){
      await this.$store.dispatch('mais/getUploadlog',this.$store.state.muncity.selected.munname)
      this.data=await this.$store.state.mais.uploadlogs
    }
  },
};
</script>
